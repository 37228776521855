/* Blueprint imports */
@import "~normalize.css";
@import "~@blueprintjs/core/lib/css/blueprint.css";
@import "~@blueprintjs/icons/lib/css/blueprint-icons.css";
@import "~@blueprintjs/datetime/lib/css/blueprint-datetime.css";
@import "~@blueprintjs/popover2/lib/css/blueprint-popover2.css";
@import "~@blueprintjs/select/lib/css/blueprint-select.css";
@import "~@blueprintjs/table/lib/css/table.css";

/* React-PDF imports */
@import "react-pdf/dist/esm/Page/AnnotationLayer.css";
@import "react-pdf/dist/esm/Page/TextLayer.css";

/* Font */
/*.font-loader {
  font-family: "Sintony";
}*/

/* Blueprint font adjustments */
h1.bp4-heading {
  line-height: 1.2em;
}

h2.bp4-heading {
  line-height: 1.2em;
}

h3.bp4-heading {
  line-height: 1.2em;
}

h4.bp4-heading {
  line-height: 1.2em;
}

h5.bp4-heading {
  line-height: 1.2em;
}

h6.bp4-heading {
  line-height: 1.2em;
}

/* Scrollbar */
/*::-webkit-scrollbar {
  width: 20px;
}

::-webkit-scrollbar-thumb {
  background-clip: content-box;
  background-color: #d6dee1;
  border: 6px solid transparent;
  border-radius: 20px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #a8bbbf;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}*/

/* Hide scrollbar */
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Responsive design */
/* Padding and border included in total width and height of elements */
* {
  box-sizing: border-box;
}

/* Column widths */
/* Extra small devices (phones, 600px and down) */
[class*="col-"] {
  width: 100%;
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .col-s-1 {
    width: 8.33%;
  }
  .col-s-2 {
    width: 16.66%;
  }
  .col-s-3 {
    width: 25%;
  }
  .col-s-4 {
    width: 33.33%;
  }
  .col-s-5 {
    width: 41.66%;
  }
  .col-s-6 {
    width: 50%;
  }
  .col-s-7 {
    width: 58.33%;
  }
  .col-s-8 {
    width: 66.66%;
  }
  .col-s-9 {
    width: 75%;
  }
  .col-s-10 {
    width: 83.33%;
  }
  .col-s-11 {
    width: 91.66%;
  }
  .col-s-12 {
    width: 100%;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .col-m-1 {
    width: 8.33%;
  }
  .col-m-2 {
    width: 16.66%;
  }
  .col-m-3 {
    width: 25%;
  }
  .col-m-4 {
    width: 33.33%;
  }
  .col-m-5 {
    width: 41.66%;
  }
  .col-m-6 {
    width: 50%;
  }
  .col-m-7 {
    width: 58.33%;
  }
  .col-m-8 {
    width: 66.66%;
  }
  .col-m-9 {
    width: 75%;
  }
  .col-m-10 {
    width: 83.33%;
  }
  .col-m-11 {
    width: 91.66%;
  }
  .col-m-12 {
    width: 100%;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .col-l-1 {
    width: 8.33%;
  }
  .col-l-2 {
    width: 16.66%;
  }
  .col-l-3 {
    width: 25%;
  }
  .col-l-4 {
    width: 33.33%;
  }
  .col-l-5 {
    width: 41.66%;
  }
  .col-l-6 {
    width: 50%;
  }
  .col-l-7 {
    width: 58.33%;
  }
  .col-l-8 {
    width: 66.66%;
  }
  .col-l-9 {
    width: 75%;
  }
  .col-l-10 {
    width: 83.33%;
  }
  .col-l-11 {
    width: 91.66%;
  }
  .col-l-12 {
    width: 100%;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .col-xl-1 {
    width: 8.33%;
  }
  .col-xl-2 {
    width: 16.66%;
  }
  .col-xl-3 {
    width: 25%;
  }
  .col-xl-4 {
    width: 33.33%;
  }
  .col-xl-5 {
    width: 41.66%;
  }
  .col-xl-6 {
    width: 50%;
  }
  .col-xl-7 {
    width: 58.33%;
  }
  .col-xl-8 {
    width: 66.66%;
  }
  .col-xl-9 {
    width: 75%;
  }
  .col-xl-10 {
    width: 83.33%;
  }
  .col-xl-11 {
    width: 91.66%;
  }
  .col-xl-12 {
    width: 100%;
  }
}

/* Hide element */
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .hide-xs {
    display: none;
  }
}

/* Small devices (portrait tablets and large phones, 768px and down) */
@media only screen and (max-width: 768px) {
  .hide-s {
    display: none;
  }
}

/* Medium devices (landscape tablets, 992px and down) */
@media only screen and (max-width: 992px) {
  .hide-m {
    display: none;
  }
}

/* Large devices (laptops/desktops, 1200px and down) */
@media only screen and (max-width: 1200px) {
  .hide-l {
    display: none;
  }
}

/* Columns float left and have 15px padding */
[class*="col-"] {
  float: left;
  padding: 15px;
}

/* Clear flow after row to avoid other elements overlaping with floating columns */
.row::after {
  clear: both;
  content: "";
  display: table;
}

/* Hide reCAPTCHA badge */
.grecaptcha-badge {
  visibility: hidden;
}
